<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryFormRef"
      size="small"
      :inline="true"
      label-width="88px"
    >
      <el-form-item label="配置类型" prop="evalType">
        <el-select
          v-model="queryParams.evalType"
          placeholder="请选择配置类型"
          clearable
          disabled
        >
          <el-option
            v-for="t in typeOptions"
            :key="t.value"
            :value="t.value"
            :label="t.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search" type="primary"
          >搜索</el-button
        >
        <el-button @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        <el-button
          @click="openForm('create')"
          icon="el-icon-plus"
          type="primary"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      stripe
      style="width: 100%"
      highlight-current-row
      v-loading="loading"
      show-overflow-tooltip
    >
      <el-table-column prop="id" label="ID" width="300" />
      <el-table-column prop="evalType" label="配置类型" width="150">
        <template #default="scope">
          {{ typeOptions.find((t) => t.value == scope.row.evalType)?.label }}
        </template>
      </el-table-column>
      <el-table-column prop="evalContent" label="配置值" width="250" show-overflow-tooltip>
        <template #default="scope">
            <p>
              {{
                scope.row.evalContent == 0
                  ? "微信原生支付"
                  : scope.row.evalContent == 1
                  ? "拉卡拉支付"
                  : "未知"
              }}
            </p>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="100" />
      <el-table-column prop="version" label="适用版本号" width="150" />
      <el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button
            size="small"
            @click="openForm('update', scope.row)"
            type="text"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 表单弹窗 -->
    <PayConfigForm ref="formRef" @success="getList" />
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { getEvalContent } from "../http/api";

import { FullTimeFormat } from "../utils/common";
import PayConfigForm from "./PayConfigForm";

import { ElMessage } from "element-plus";

export default {
  name: "Payment", // 支付配置管理
  components: { PayConfigForm },
  setup() {
    const loading = ref(true);
    const list = ref([]);
    //声明数据
    let queryParams = reactive({
      evalType: 6,
    });
    const queryFormRef = ref();

    let resetQuery = () => {
      queryFormRef.value.resetFields();
      handleQuery();
    };

    const typeOptions = ref([
      {
        label: "支付配置",
        value: 6,
      },
    ]);

    // 获取列表
    const getList = async () => {
      loading.value = true;
      try {
        const data = await getEvalContent(queryParams);
        list.value = data;
      } finally {
        loading.value = false;
      }
    };

    let handleQuery = () => {
      getList();
    };

    /** 添加/修改操作 */
    const formRef = ref();
    const openForm = (type, row) => {
      formRef.value?.open(type, row);
    };

    const formatTime = (date) => {
      return FullTimeFormat(date);
    };

    // 初始化
    onMounted(() => {
      getList();
    });

    return {
      loading,
      queryParams,
      queryFormRef,
      formRef,
      list,
      typeOptions,
      getList,
      handleQuery,
      resetQuery,
      formatTime,
      openForm,
    };
  },
};
</script>

<style lang="scss" scoped></style>
