<template>
  <el-dialog :title="dialogTitle" v-model="dialogVisible" width="700px">
    <el-form
      ref="formRef"
      :model="formData"
      size="small"
      :rules="formRules"
      v-loading="formLoading"
      label-width="99px"
    >
      <el-form-item label="配置类型" prop="evalType">
        <el-select
          v-model="formData.evalType"
          placeholder="请选择配置类型"
          clearable
          disabled
        >
          <el-option :key="6" :value="6" label="支付配置" />
        </el-select>
      </el-form-item>
      <el-form-item label="适用版本号" prop="version">
        <el-input
          v-model.trim="formData.version"
          placeholder="请输入适用版本号，如：`1.4.53`"
          clearable
          maxlength="20"
        />
      </el-form-item>
      <el-form-item label="配置值" prop="evalContent">
        <el-radio-group v-model="formData.evalContent">
          <el-radio label="0">微信原生支付</el-radio>
          <el-radio label="1">拉卡拉支付</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number
          v-model="formData.sort"
          :min="0"
          :precision="0"
          placeholder="请输入排序"
          style="width: 240px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onClickSubmit" type="primary" :disabled="formLoading"
        >提交</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import { nextTick, reactive, ref } from "vue";
import { ElMessage } from 'element-plus';
import { createEvalContent, getEvalDetail } from "../http/api";
import { updatePayConfig } from "../http/pay";

/** 支付配置表单 **/
defineOptions({ name: "PayConfigForm" });

const dialogVisible = ref(false); // 弹窗的是否展示
const dialogTitle = ref(''); // 弹窗标题
const formLoading = ref(false); // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formType = ref(''); // 表单类型： create - 新增；update - 修改
const formData = ref({});
const formRules = reactive({
  id: [{ required: true, message: "id不能为空", trigger: "blur" }],
  evalType: [{ required: true, message: "配置类型不能为空", trigger: "blur" }],
  evalContent: [{ required: true, message: "配置值不能为空", trigger: ["blur", 'change'] }],
  sort: [{ required: true, message: "排序不能为空", trigger: ["blur", 'change'] }],
  version: [{ required: true, message: "适用版本号不能为空", trigger: "blur" }],
});
const formRef = ref(); // 表单 Ref

/** 打开弹窗 */
const open = async (type, row) => {
  dialogVisible.value = true;
  dialogTitle.value = type == 'create' ? '新增' : '编辑'
  formType.value = type
  resetForm();
  // 修改时，设置数据
  if (row?.id) {
    formLoading.value = true;
    try {
      let data = await getEvalDetail({id: row.id})
      formData.value = data
    } finally {
      formLoading.value = false
    }
  }
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(["success"]); // 定义 success 事件，用于操作成功后的回调

const onClickSubmit = async () => {
    // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;

  submitForm()
}

const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;
  // 提交请求
  formLoading.value = true;
  try {
    if (formType.value == 'create') {
        let {code, message} = await createEvalContent(formData.value)
        if (code && code != 200) {
            ElMessage.error({
                message: message || '新增失败',
                type: 'error'
            })
            return
        }
        ElMessage.success({
          message: '新增成功',
          duration: 2000,
        })
    } else {
        let {code, message} = await updatePayConfig(formData.value)
        if (code && code != 200) {
            ElMessage.error({
                message: message || '更新失败',
                type: 'error'
            })
            return
        }
        ElMessage.success({
          message: '更新成功',
          duration: 2000,
        })
    }
    dialogVisible.value = false
    // 发送操作成功的事件
    emit("success");
  } catch (e) {
    ElMessage.error({
        message: e || '提交失败',
        offset:130,
        duration:3000
    });
  } finally {
    formLoading.value = false;
  }
};

/** 重置表单 */
const resetForm = () => {
  formData.value = {
    id: undefined,
    evalType: 6,
    evalContent: undefined,
    sort: undefined,
  };
  formRef.value?.resetFields();
};
</script>

<style lang="scss" scoped></style>
