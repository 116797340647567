import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

/**
 * 更新支付配置
 */
export const updatePayConfig = (data) => {
  return $http.post(`/app/info/evaluationconfiguration/back/update`, data);
};
